:root {
    --ascent-text: #cc3300;
    --footer-text: cornsilk;
    --header-text: #3B6B7D;
    --page-background-color: #f8f6ed;

}

body {
    background-color: var(--page-background-color);
}

.bannerText {
    color: var(--ascent-text);
    position: relative;
    top: -50%;
    left: 25%;
}


.blogTitle {
    color: var(--header-text);
    font-size: 2.1rem;
}

.dividerLine {
    border-bottom: var(--ascent-text) solid .3rem;
    margin-bottom: 30px;
}

.navItem {
    color: white;
}

.navBar {
    color: var(--ascent-text);
    border-bottom: var(--ascent-text) solid .3rem;
}

.navBar a {
    color: var(--ascent-text);
    text-decoration: none;
}

.navBar a:hover {
    color: var(--ascent-text);
    text-decoration: underline;
}

.navBar .nav-link {
    color: var(--ascent-text) !important;
}


.footer {
    width: 100%;
    background-color: var(--ascent-text);
    padding: 1rem;
    margin-top: 2rem;
}

.footer a {
    color: var(--footer-text);
    text-decoration: none;
}

.footer a:hover {
    color: var(--footer-text);
    text-decoration: underline;
}

.footer h5 {
    color: var(--footer-text);
}

.footer ul {
    color: var(--footer-text);
    list-style-type: none;
    padding: 0px;
}

.footer .copyright {
    color: var(--footer-text);
    font-size: small;
    text-align: center;
    padding-top: 1rem;
}

.footer .social_media {
    font-size: 1.5rem;

}


.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.recipes {
    color: black;
}

.recipes a {
    color: black;
    text-decoration: none;
}

.recipes a:hover {
    color: var(--ascent-text);
    text-decoration: none;
}

.ingredients {
    padding-left: 2rem;
}

.recipe h6 {
    color: var(--ascent-text);
    padding-top: 1.2rem;
}

.recipe li {
    margin-bottom: 1.2rem;
}